import React, { useRef, useEffect, createRef } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { css, useTheme } from "@emotion/react"
import { fluidRange, rgba } from "polished"
import { useDispatch, useSelector } from "react-redux"
import { gsap } from "gsap/all"

import { setMenuActive } from "../store/menu"
import BaseSvg from "../components/BaseSvg"
import TheHeaderMenuToggle from "../components/TheHeaderMenuToggle"

const TheHeaderMenu = ({ itemsMenu, itemsSocial, itemsContact }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const isMenuActive = useSelector(state => state.menu.active)
  const ref = useRef(null)
  const refTimeline = useRef(null)
  const refTimelines = useRef(null)
  const refItems = useRef(itemsMenu.map(() => createRef(null)))

  const handleMenuToggle = event => {
    if (!isMenuActive) {
      refTimeline.current.play()
    } else {
      refTimeline.current.reverse()
    }
    dispatch(setMenuActive(!isMenuActive))
  }
  const timelineItem = refItem => {
    const element = refItem.current
    const textForeground = element.querySelectorAll(".item__text--foreground")
    const textBackground = element.querySelectorAll(".item__text--background")
    const timeline = gsap.timeline({ paused: true })

    timeline
      .fromTo(
        textForeground,
        {
          y: "0%",
        },
        {
          y: "-100%",
          ease: "none",
        },
        0
      )
      .fromTo(
        textBackground,
        {
          y: "100%",
        },
        {
          y: "0%",
          ease: "none",
        },
        0
      )

    return timeline
  }
  const handleMouseEnter = index => {
    const timeline = refTimelines.current[index]

    gsap.to(timeline, {
      time: timeline.duration(),
      ease: "power4.out",
      duration: 1.2,
    })
  }
  const handleMouseLeave = index => {
    const timeline = refTimelines.current[index]

    gsap.to(timeline, {
      time: 0,
      ease: "power4.out",
      duration: 1.2,
      overwrite: true,
    })
  }

  useEffect(() => {
    refTimelines.current = itemsMenu.map((item, index) => {
      return timelineItem(refItems.current[index])
    })
  }, [])

  useEffect(() => {
    const element = ref.current
    const modal = element.querySelectorAll(".modal")
    const modalOverlay = element.querySelectorAll(".modalOverlay")
    const modalDialogBackground = element.querySelectorAll(
      ".modalDialogBackground"
    )
    const item = element.querySelectorAll(".item")
    const heading = element.querySelectorAll(".heading")
    const social = element.querySelectorAll(".social")
    const contact = element.querySelectorAll(".contact")
    const timeline = gsap.timeline({ paused: true })

    timeline
      .fromTo(
        document.body,
        { overflow: "auto" },
        { overflow: "hidden", duration: 0.01 },
        0
      )
      .fromTo(
        modal,
        { display: "none" },
        { display: "block", duration: 0.01 },
        0
      )
      .fromTo(
        modalOverlay,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.75, ease: "power2.out" },
        0
      )
      .fromTo(
        modalDialogBackground,
        { xPercent: 100 },
        { xPercent: 0, duration: 0.75, ease: "power2.out" },
        0
      )
      .fromTo(
        item,
        {
          clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)",
        },
        {
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          duration: 0.3,
          ease: "power2.out",
        },
        0.4
      )
      .fromTo(
        [heading, social, contact],
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.3, ease: "power2.out" },
        0.4
      )

    refTimeline.current = timeline
  }, [])

  css.modal = css`
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    mix-blend-mode: normal;
  `

  css.modalOverlay = css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${rgba("#252627", 0.7)};
  `

  css.modalDialog = css`
    overflow-y: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 500px;
    height: 100%;
    ${fluidRange(
      {
        prop: "padding",
        fromSize: "40px",
        toSize: "50px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    color: ${theme.colors.typeAndTixel.one};
  `

  css.modalDialogBackground = css`
    background-color: ${theme.colors.global.light};
  `

  css.menu = css`
    list-style: none;
    margin: 0;
    padding: 0;
    clear: both;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: `${190 * 0.5}px`,
        toSize: "190px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: `${150 * 0.5}px`,
        toSize: "150px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.menuItem = css`
    &:not(:first-of-type) {
      ${fluidRange(
        {
          prop: "marginTop",
          fromSize: `${60 * 0.5}px`,
          toSize: "60px",
        },
        theme.breakpoints.mobile,
        theme.breakpoints.desktop
      )}
    }
  `

  css.menuItemLink = css`
    position: relative;
    display: block;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: `${60 * 0.5}px`,
        toSize: "60px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
    font-weight: ${theme.fontWeight.bold};
    line-height: 1;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
  `

  css.menuItemLinkTextBackground = css`
    position: absolute;
    top: 0;
    left: 0;
  `

  css.heading = css`
    font-size: 18px;
    font-weight: ${theme.fontWeight.normal};
    opacity: 0.5;

    ${fluidRange(
      {
        prop: "marginTop",
        fromSize: `${40 * 0.5}px`,
        toSize: "40px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}

    ${fluidRange(
      {
        prop: "marginBottom",
        fromSize: `${20 * 0.5}px`,
        toSize: "20px",
      },
      theme.breakpoints.mobile,
      theme.breakpoints.desktop
    )}
  `

  css.social = css`
    list-style: none;
    margin: 0;
    padding: 0;

    display: inline-grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 40px;
  `

  css.socialItemLink = css`
    text-decoration: none;
    color: inherit;
  `

  css.socialItemLinkIcon = css`
    width: 30px;
  `

  css.contact = css`
    list-style: none;
    margin: 0;
    padding: 0;

    display: inline-grid;
  `

  css.contactItemLink = css`
    font-size: 22px;
    font-weight: ${theme.fontWeight.bold};
    text-decoration: underline;
    color: inherit;
  `

  return (
    <nav ref={ref}>
      <TheHeaderMenuToggle
        isMenuActive={isMenuActive}
        handleMenuToggle={handleMenuToggle}
      />
      <div css={css.modal} className="modal">
        <div css={css.modalOverlay} className="modalOverlay" />
        <div
          css={[css.modalDialog, css.modalDialogBackground]}
          className="modalDialogBackground"
        />
        <div css={css.modalDialog}>
          <ul css={css.menu}>
            {itemsMenu.map((item, index) => {
              return (
                <li key={index} css={css.menuItem}>
                  <Link
                    ref={refItems.current[index]}
                    css={css.menuItemLink}
                    to={item.link}
                    className="item cursor"
                    onClick={handleMenuToggle}
                    onMouseEnter={event => {
                      handleMouseEnter(index)
                    }}
                    onMouseLeave={event => {
                      handleMouseLeave(index)
                    }}
                  >
                    <div className="item__text--foreground">{item.text}</div>
                    <div
                      css={css.menuItemLinkTextBackground}
                      className="item__text--background"
                    >
                      {item.text}
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
          <h2 css={css.heading} className="heading">
            Socials
          </h2>
          <ul css={css.social} className="social">
            {itemsSocial.map((item, index) => {
              return (
                <li key={index}>
                  <a css={css.socialItemLink} href={item.link} target="_blank">
                    <BaseSvg css={css.socialItemLinkIcon} src={item.icon} />
                  </a>
                </li>
              )
            })}
          </ul>
          <h2 css={css.heading} className="heading">
            Get in touch
          </h2>
          <ul css={css.contact} className="contact">
            {itemsContact.map((item, index) => {
              return (
                <li key={index}>
                  <a css={css.contactItemLink} href={item.link}>
                    {item.text}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
}

TheHeaderMenu.defaultProps = {
  itemsMenu: [
    {
      text: "Work.",
      link: "/work",
    },
    {
      text: "About.",
      link: "/about",
    },
    {
      text: "Contact.",
      link: "/contact",
    },
    // {
    //   text: "Showreel.",
    //   link: "#",
    // },
  ],
  itemsSocial: [
    {
      icon: "instagram",
      text: "Instagram",
      link: "https://www.instagram.com/typepixel",
    },
    // {
    //   icon: "dribbble",
    //   text: "Dribbble",
    //   link: "https://dribbble.com/typeandpixel",
    // },
    {
      icon: "linkedin",
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/typeandpixel",
    },
  ],
  itemsContact: [
    {
      text: "hello@typeandpixel.com.au",
      link: "mailto:hello@typeandpixel.com.au",
    },
  ],
}

export default TheHeaderMenu
